import { z } from "zod";
import { Identity } from "../utils/identity";
import { Decimal } from "../utils/zod";
import { ZodSchema, decimalSchema } from "../utils/zod";

export type DealUpdateEvent = {
  id: string;
  tokenAmountNew: Decimal | null;
  tokenAmountOld: Decimal | null;
  aprNew: Decimal | null;
  aprOld: Decimal | null;
  validatorCommissionNew: Decimal | null;
  validatorCommissionOld: Decimal | null;
  tokenPriceNew: Decimal | null;
  tokenPriceOld: Decimal | null;
  usdGrantedNew: Decimal | null;
  usdGrantedOld: Decimal | null;
  updateNote: string | null;
  startDate: Date | null;
  endDate: Date | null;
  maxMonths: number | null;
  createdById: string;
  delegationId: string | null;
  grantId: string | null;
  createdAt: Date;
  switchStaticRevenue: boolean | null;
  switchStaticApr: boolean | null;
  active: boolean | null;
};

export type DealUpdateEventNew = Identity<
  Partial<
    Omit<DealUpdateEvent, "id" | "createdAt" | "tokenPriceOld" | "aprOld" | "validatorCommissionOld" | "tokenAmountOld">
  > & {
    createdById: string;
    createdAtOverride?: Date | null;
  }
>;

export type DealUpdateEventUpdate = Identity<Partial<Omit<DealUpdateEvent, "id" | "createdAt">>>;

export const dealUpdateEventSchema = z.object({
  id: z.string().uuid(),
  tokenAmountNew: decimalSchema.nullable(),
  tokenAmountOld: decimalSchema.nullable(),
  aprNew: decimalSchema.nullable(),
  aprOld: decimalSchema.nullable(),
  validatorCommissionNew: decimalSchema.nullable(),
  validatorCommissionOld: decimalSchema.nullable(),
  tokenPriceNew: decimalSchema.nullable(),
  tokenPriceOld: decimalSchema.nullable(),
  active: z.boolean().nullable(),
  activeOld: z.boolean().nullable(),
  usdGrantedNew: decimalSchema.nullable(),
  usdGrantedOld: decimalSchema.nullable(),
  updateNote: z.string().nullable(),
  startDate: z.coerce.date().nullable(),
  endDate: z.coerce.date().nullable(),
  maxMonths: z.number().int().positive().nullable(),
  createdById: z.string().uuid(),
  delegationId: z.string().uuid().nullable(),
  grantId: z.string().uuid().nullable(),
  createdAt: z.coerce.date(),
  switchStaticRevenue: z.boolean().nullable(),
  switchStaticApr: z.boolean().nullable(),
}) satisfies ZodSchema<DealUpdateEvent>;

export const dealUpdateEventCreateSchema = dealUpdateEventSchema
  .omit({
    id: true,
    createdAt: true,
    aprOld: true,
    validatorCommissionOld: true,
    tokenPriceOld: true,
    tokenAmountOld: true,
    activeOld: true,
    usdGrantedOld: true,
  })
  .extend({
    delegationId: z.string().uuid().nullable(),
    grantId: z.string().uuid().nullable(),
  })
  .refine(
    (data) => (data.delegationId !== null) !== (data.grantId !== null),
    "Either delegationId or grantId must be provided, but not both.",
  ); // satisfies ZodSchema<DealUpdateEventNew>;

export const dealUpdateEventUpdateSchema = dealUpdateEventSchema
  .partial()
  .extend({
    id: z.string().uuid(),
  })
  .omit({
    createdAt: true,
  }) satisfies ZodSchema<DealUpdateEventUpdate>;

// Base schema for all update events
const baseUpdateEventSchema = z.object({
  updateNote: z.string().nullable().default(null),
  createdAtOverride: z.coerce.date().nullable().default(null),
  switchStaticRevenue: z.boolean().nullable().default(null),
  startDate: z.coerce.date().nullable().default(null),
  endDate: z.coerce.date().nullable().default(null),
  active: z.boolean().nullable().default(null),
});

// Schema for token grant update events
export const tokenGrantUpdateEventSchema = baseUpdateEventSchema.extend({
  grantId: z.string().uuid(),
  tokenAmountNew: decimalSchema.nullable().default(null),
  tokenPriceNew: decimalSchema.nullable().default(null),
  maxMonths: z.number().int().positive().nullable().default(null),
});

// Schema for USD grant update events
export const usdGrantUpdateEventSchema = baseUpdateEventSchema.extend({
  grantId: z.string().uuid(),
  usdGrantedNew: decimalSchema.nullable().default(null),
  maxMonths: z.number().int().positive().nullable().default(null),
});

// Schema for delegation update events
export const delegationUpdateEventSchema = baseUpdateEventSchema.extend({
  delegationId: z.string().uuid(),
  tokenAmountNew: decimalSchema.nullable().default(null),
  tokenPriceNew: decimalSchema.nullable().default(null),
  aprNew: decimalSchema.nullable().default(null),
  switchStaticApr: z.boolean().nullable().default(null),
  validatorCommissionNew: decimalSchema.nullable().default(null),
});

export const dealUpdateEventUnionSchema = z.union([
  tokenGrantUpdateEventSchema,
  usdGrantUpdateEventSchema,
  delegationUpdateEventSchema,
]) satisfies ZodSchema<Omit<DealUpdateEventNew, "createdById">>;
