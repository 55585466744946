import { z } from "zod";
import { Identity } from "../utils/identity";
import { Decimal } from "../utils/zod";
import { ZodSchema, decimalSchema } from "../utils/zod";

export type DealGrant = {
  id: string;
  tokenAmountSignDate: Decimal | null;
  tokenAmountCurrent: Decimal | null;
  staticDailyRevenue: boolean;
  tokenPriceSignDate: Decimal | null;
  tokenPriceCurrent: Decimal | null;
  usdGrantedSignDate: Decimal | null;
  usdGrantedCurrent: Decimal | null;
  tokenId: string | null;
  startDate: Date;
  endDate: Date;
  maxMonths: number | null;
  createdById: string;
  partnerDealId: string;
  grantType: "USD" | "TOKEN";
  active: boolean;
};

// Define base properties for DealGrantNew
type BaseDealGrantNew = Identity<
  Omit<
    DealGrant,
    | "id"
    | "usdGrantedSignDate"
    | "usdGrantedCurrent"
    | "tokenAmountSignDate"
    | "tokenId"
    | "tokenAmountCurrent"
    | "tokenPriceSignDate"
    | "tokenPriceCurrent"
    | "staticDailyRevenue"
    | "maxMonths"
    | "endDate"
    | "startDate"
    | "active"
  > & {
    startDate?: Date;
    staticDailyRevenue?: boolean;
    maxMonths?: number;
    endDate?: Date;
    active?: boolean;
  }
>;

// Define USD-based grant properties
export type UsdBasedGrantNew = Identity<
  {
    grantType: "USD";
    usdGrantedSignDate: Decimal;
    usdGrantedCurrent?: Decimal;
  } & BaseDealGrantNew
>;

// Define token-based grant properties
export type TokenBasedGrantNew = Identity<
  {
    grantType: "TOKEN";
    tokenAmountSignDate: Decimal;
    tokenPriceSignDate?: Decimal;
    tokenAmountCurrent?: Decimal;
    tokenPriceCurrent?: Decimal;
    tokenId: string;
  } & BaseDealGrantNew
>;

export type DealGrantNew = Identity<
  (UsdBasedGrantNew & { grantType: "USD" }) | (TokenBasedGrantNew & { grantType: "TOKEN" })
>;

export type DealGrantUpdate = Identity<Partial<Omit<DealGrant, "id" | "partnerDealId">> & { id: string }>;

// Base schema for DealGrant
const baseDealGrantSchema = z.object({
  id: z.string().uuid(),
  staticDailyRevenue: z.boolean(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  maxMonths: z.number().int().positive().nullable(),
  createdById: z.string().uuid(),
  partnerDealId: z.string().uuid(),
});

// Schema for USD-based grant
const usdBasedGrantSchema = baseDealGrantSchema.extend({
  usdGrantedSignDate: decimalSchema,
  usdGrantedCurrent: decimalSchema,
  tokenAmountSignDate: decimalSchema,
  tokenAmountCurrent: decimalSchema,
  tokenPriceSignDate: decimalSchema,
  tokenPriceCurrent: decimalSchema,
  tokenId: z.string().uuid(),
  grantType: z.literal("USD"),
});

// Schema for token-based grant
const tokenBasedGrantSchema = baseDealGrantSchema.extend({
  tokenAmountSignDate: decimalSchema,
  tokenAmountCurrent: decimalSchema,
  tokenPriceSignDate: decimalSchema,
  tokenPriceCurrent: decimalSchema,
  usdGrantedSignDate: decimalSchema,
  usdGrantedCurrent: decimalSchema,
  grantType: z.literal("TOKEN"),
});

// Combined schema for DealGrant
export const dealGrantSchema = z.union([usdBasedGrantSchema, tokenBasedGrantSchema]);

// Schemas for creating a new DealGrant

export const dealGrantBaseSchema = z.object({
  grantType: z.enum(["USD", "TOKEN"]),
  createdById: z.string().uuid(),
  startDate: z.coerce
    .date()
    .default(() => new Date())
    .optional(),
  endDate: z.coerce.date().optional(),
  maxMonths: z.number().int().positive().optional(),
  staticDailyRevenue: z.boolean().default(true).optional(),
  partnerDealId: z.string().uuid(),
  active: z.boolean().default(true).optional(),
});

export const usdGrantAddins = z.object({
  usdGrantedSignDate: decimalSchema,
  usdGrantedCurrent: decimalSchema.optional(),
});

export const dealUsdGrantCreateSchema = dealGrantBaseSchema.merge(usdGrantAddins).extend({
  grantType: z.literal("USD"),
}) satisfies ZodSchema<UsdBasedGrantNew>;

export const tokenGrantAddins = z.object({
  tokenAmountSignDate: decimalSchema,
  tokenPriceSignDate: decimalSchema.optional(),
  tokenId: z.string().uuid(),
  tokenAmountCurrent: decimalSchema.optional(),
  tokenPriceCurrent: decimalSchema.optional(),
});

export const dealTokenGrantCreateSchema = dealGrantBaseSchema.merge(tokenGrantAddins).extend({
  grantType: z.literal("TOKEN"),
}) satisfies ZodSchema<TokenBasedGrantNew>;

export const dealGrantCreateSchema = z.discriminatedUnion("grantType", [
  dealUsdGrantCreateSchema,
  dealTokenGrantCreateSchema,
]);

export const nonDiscriminatedUpdateSchema = dealGrantBaseSchema
  .merge(usdGrantAddins.partial())
  .merge(tokenGrantAddins.partial());

export const dealGrantUpdateSchema = dealUsdGrantCreateSchema.merge(dealTokenGrantCreateSchema).partial().extend({
  id: z.string().uuid(),
}) satisfies ZodSchema<DealGrantUpdate>;
