import { z } from "zod";

export const legacySchema = z.object({
  userId: z.string().uuid(),
  adhocSubmit: z.boolean().default(false),
  adhocApprove: z.boolean().default(false),
  rewardsEdit: z.boolean().default(false),
  admin: z.boolean().default(false),
});

export type LegacyPermissions = z.infer<typeof legacySchema>;
