import { z } from "zod";
import { makePaginatedResultSchema, makeSortedPageOptsSchema } from "../pagination/pagination";

// Usage for aggregated studio query executions

export const usageAggregateByQuerySchema = z.object({
  queryId: z.string().uuid(),
  name: z.string().nullish(),
  ttlMinutes: z.number().nullish(),
  paidSeconds: z.number().min(0),
  freeSeconds: z.number().min(0),
  lastRunErrorMessage: z.string().nullable(),
  lastRunState: z.string(),
  lastRunAt: z.coerce.date().nullish(),
  resultLastAccessedAt: z.coerce.date().nullish(),
});

const usageAggregateByQuerySortSchema = z.enum([
  "lastRunAt",
  "name",
  "ttlMinutes",
  "paidSeconds",
  "freeSeconds",
  "lastRunState",
  "resultLastAccessedAt",
]);

export const usageAggregateQueryOptsSchema = makeSortedPageOptsSchema(usageAggregateByQuerySortSchema).extend({
  profileId: z.string(),
  month: z.coerce.number().min(1).max(12),
  year: z.coerce.number().min(2000),
});
export const usageAggregateQueryPageData = makePaginatedResultSchema(usageAggregateByQuerySchema);
export type UsageAggregateByQuery = z.infer<typeof usageAggregateByQuerySchema>;
export type UsageAggregateByQueryOpts = z.infer<typeof usageAggregateQueryOptsSchema>;

// Usage for API query executions

export const usageForApiQuerySchema = z.object({
  queryRunId: z.string(),
  endedAt: z.coerce.date(),
  sql: z.string().nullish(),
  querySeconds: z.number(),
  errorMessage: z.string().nullable(),
  state: z.string(),
});

const usageForApiQuerySortSchema = z.enum(["endedAt", "querySeconds", "state"]);

export const usageForApiQueryOptsSchema = makeSortedPageOptsSchema(usageForApiQuerySortSchema).extend({
  profileId: z.string(),
  month: z.coerce.number().min(1).max(12),
  year: z.coerce.number().min(2000),
});

export type UsageForApiQuerySort = z.infer<typeof usageForApiQuerySortSchema>;
export type UsageForApiQueryOpts = z.infer<typeof usageForApiQueryOptsSchema>;
export type UsageForApiQuery = z.infer<typeof usageForApiQuerySchema>;

// Usage for Snowflake Direct query

export const usageForSnowflakeDirectQuerySchema = z.object({
  endedAt: z.coerce.date(),
  sql: z.string().nullish(),
  querySeconds: z.number(),
});

const usageForSnowflakeDirectQuerySortSchema = z.enum(["endedAt", "querySeconds"]);

export const usageForSnowflakeDirectQueryOptsSchema = makeSortedPageOptsSchema(
  usageForSnowflakeDirectQuerySortSchema,
).extend({
  profileId: z.string(),
  month: z.coerce.number().min(1).max(12),
  year: z.coerce.number().min(2000),
});
export type UsageForSnowflakeDirectQuerySort = z.infer<typeof usageForSnowflakeDirectQuerySortSchema>;
export type UsageForSnowflakeDirectQueryOpts = z.infer<typeof usageForSnowflakeDirectQueryOptsSchema>;
export type UsageForSnowflakeDirectQuery = z.infer<typeof usageForSnowflakeDirectQuerySchema>;

// Usage by query id for run log

export const usageByQueryId = z.object({
  queryRunId: z.string(),
  endedAt: z.date(),
  warehouse: z.string(),
  state: z.string(),
  errorMessage: z.string().nullable(),
  executionType: z.string(),
  querySeconds: z.number(),
});

const usageByQueryIdSortSchema = z.enum(["endedAt", "querySeconds", "state", "warehouse", "executionType"]);
export const usageByQueryIdOptsSchema = makeSortedPageOptsSchema(usageByQueryIdSortSchema).extend({
  month: z.coerce.number().min(1).max(12),
  year: z.coerce.number().min(2000),
});

export type UsageByQueryId = z.infer<typeof usageByQueryId>;
export type UsageByQueryIdOpts = z.infer<typeof usageByQueryIdOptsSchema>;
export type UsageByQueryIdSort = z.infer<typeof usageByQueryIdSortSchema>;
