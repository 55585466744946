import { z } from "zod";
import { Decimal } from "../utils/zod";
import { ZodSchema, decimalSchema } from "../utils/zod";

export type GrantDailyArchive = {
  id: string;
  grantId: string;
  tokenPriceCurrent: Decimal | null;
  tokenAmountCurrent: Decimal | null;
  usdGrantedCurrent: Decimal | null;
  estValueUsd: Decimal;
  createdAt: Date;
  dateOfCreation: string;
  dailyArchiveId: string;
};

export type GrantDailyArchiveNew = Omit<GrantDailyArchive, "id" | "createdAt">;

export const grantDailyArchiveSchema = z.object({
  id: z.string().uuid(),
  grantId: z.string().uuid(),
  tokenPriceCurrent: decimalSchema.nullable(),
  tokenAmountCurrent: decimalSchema,
  estValueUsd: decimalSchema,
  usdGrantedCurrent: decimalSchema.nullable(),
  createdAt: z.coerce.date(),
  dateOfCreation: z.string(),
  dailyArchiveId: z.string().uuid(),
  delegationId: z.string().uuid(),
  aprCurrent: decimalSchema.nullable(),
  validatorCommissionCurrent: decimalSchema.nullable(),
}) satisfies ZodSchema<GrantDailyArchive>;

export const grantDailyArchiveCreateSchema = grantDailyArchiveSchema.omit({
  id: true,
  createdAt: true,
}) satisfies ZodSchema<GrantDailyArchiveNew>;
