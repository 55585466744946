import { z } from "zod";
import { Chain } from "../chain";
import { Token } from "../token";
import { User } from "../user";
import { Identity } from "../utils/identity";
import { ZodSchema } from "../utils/zod";

export type PartnerChainType = "SCORES" | "VALIDATIONS" | "PAYMENTS";
export const partnerChainTypesAsArray = ["SCORES", "VALIDATIONS", "PAYMENTS"] as const;

export type PartnerChain = {
  partnerId: string;
  id: string;
  chainId: string;
  createdAt: Date;
  updatedAt: Date;
  createdById: string;
  updatedById: string;
  type: PartnerChainType;
  tokenId: string | null;
};

export type PartnerChainWithAssociations = Identity<
  PartnerChain & {
    chain: Chain;
    token: Token | null;
    updatedBy: User;
  }
>;

export type PartnerChainNew = Pick<PartnerChain, "partnerId" | "chainId" | "type"> & {
  tokenId?: string | null;
};

export const partnerChainNewSchema = z.object({
  partnerId: z.string().uuid(),
  chainId: z.string().uuid(),
  tokenId: z.string().uuid().nullable().optional(),
  type: z.enum(partnerChainTypesAsArray),
}) satisfies ZodSchema<PartnerChainNew>;
